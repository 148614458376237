<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(update)">
        <section class="mb-2">
          <label>Name</label>
          <validation-provider
            v-slot="validationContext"
            :rules="{ required: true }"
            name="Name"
          >
            <b-form-input
              v-model="tag.name"
              :state="getValidationState(validationContext)"
              placeholder="Enter tag name"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </section>

        <section class="mb-2">
          <validation-provider
            v-slot="validationContext"
            :rules="{ isImageFile }"
            name="Image"
          >
            <label>Image (Optional)</label>
            <b-form-file
              v-model="image"
              :state="getValidationState(validationContext)"
              accept=".jpg, .jpeg, .png, .webp"
              drop-placeholder="Drop file here..."
              placeholder="Choose an image or drag one here..."
              @input="selectImage"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>

          <small>
            This will replace the existing image. This action can not be undone.
          </small>
        </section>

        <div class="float-right mt-2">
          <b-button class="mr-2" @click="$emit('close')">Cancel</b-button>
          <b-button type="submit" variant="primary">Save</b-button>
        </div>
      </b-form>
    </validation-observer>

    <b-modal
      v-if="image"
      id="image-cropper-modal"
      hide-footer
      size="lg"
      title="Crop Image"
    >
      <thumbnail-cropper-modal
        :chosen-image="imageBase64"
        :file-type="image.type"
        @close="closeImageCropperModal"
        @crop="cropImage"
      />
    </b-modal>
  </div>
</template>

<script>
import { isImageFile } from "@/@core/utils/validations/validations";
import ThumbnailCropperModal from "@/views/theHub/sections/ThumbnailCropperModal.vue";
import { validatorIsImageFile } from "@/@core/utils/validations/validators";

export default {
  name: "TagEditor",
  components: { ThumbnailCropperModal },
  props: {
    existing: {
      type: Object,
      default: null,
    },
    parentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      allTags: [],
      tag: {
        name: "",
        featured: false,
      },
      image: null,
      imageBase64: "",
      loading: false,
    };
  },
  computed: {
    isImageFile() {
      return isImageFile;
    },
  },
  mounted() {
    if (this.$props.existing) {
      this.tag = {
        name: this.$props.existing.name,
      };
    }
  },
  methods: {
    async update() {
      let tag = {
        name: this.tag.name,
        image: this.image,
      };

      if (this.$props.parentId) {
        tag.parent_id = this.$props.parentId;
      }

      this.$emit("update", tag);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async selectImage() {
      if (validatorIsImageFile(this.image)) {
        this.imageBase64 = await this.toBase64(this.image);
        this.$bvModal.show("image-cropper-modal");
      }
    },
    async toBase64(file) {
      if (!file) return null;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    cropImage(_, blob) {
      this.image = new File([blob], "image." + blob.type.split("/")[1], {
        type: blob.type,
      });
      this.$bvModal.hide("image-cropper-modal");
    },
    closeImageCropperModal() {
      this.$bvModal.hide("image-cropper-modal");
      this.image = null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
