<template>
  <div class="activity-page">
    <b-card class="" title="Tags">
      <b-btn
        v-if="$can('Create', 'TheHub.Tags')"
        class="float-right"
        variant="success"
        @click="showCreateModal"
      >
        Create Tag
      </b-btn>
      <b-card-text>
        This page shows all tags used for articles published on TheHub.
      </b-card-text>
    </b-card>

    <b-card class="mb-0" no-body>
      <div class="row px-2 py-1 align-items-center justify-content-between">
        <section class="col-4">
          <label>Search</label>
          <b-input-group>
            <b-form-input
              v-model="filters.search"
              class="d-inline-block"
              placeholder="Search by name..."
              @keydown.enter="getMoreTags(1)"
            />
            <b-input-group-append>
              <b-button @click="getMoreTags(1)">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </section>

        <section class="col-2 d-flex justify-content-end align-items-center">
          <b-button @click="clearFilters">Clear filters</b-button>
        </section>
      </div>
      <section v-if="!loading">
        <b-table
          :current-page="currentPage"
          :fields="headers"
          :items="tags"
          :per-page="0"
          class="data-table"
          hover
        >
          <template #cell(actions)="data">
            <router-link
              :to="{ name: 'tag-details', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              View tag
            </router-link>
          </template>
        </b-table>
        <b-row>
          <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
            <b-pagination
              v-model="currentPage"
              :per-page="filters.page_size"
              :total-rows="total_count"
              class="my-0 mx-1"
              @change="getMoreTags"
            />
          </b-col>
          <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-create-tag" hide-footer title="Create Tag">
      <tag-editor-modal
        :parent-tags="potentialParentTags"
        @close="closeModals"
        @update="createTag"
      />
    </b-modal>
  </div>
</template>

<script>
import TheHubService from "@/services/TheHubService";
import TagEditorModal from "@/views/theHub/sections/TagEditorModal.vue";

export default {
  name: "Tags",
  components: { TagEditorModal },
  data() {
    return {
      headers: [
        {
          key: "name",
          label: "Name",
          class: "col-6",
        },
        {
          key: "article_count",
          label: "Article Count",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      tags: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 15,
        search: "",
        parent_id: "0",
      },
      total_count: 0,
      timeout: null,
      loading: false,
    };
  },
  computed: {
    potentialParentTags() {
      return this.tags;
    },
  },
  mounted() {
    this.getTags();
  },
  methods: {
    getMoreTags(page) {
      this.filters.page = page - 1;
      this.currentPage = page;
      this.getTags();
    },
    async getTags() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      try {
        let { data } = await TheHubService.listTags(this.filters);
        this.tags = data.data;

        this.filters.page = data.meta.page;
        this.filters.page_size = data.meta.page_size;
        this.total_count = data.meta.total_count;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get tags, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async createTag(tag) {
      this.loading = true;
      try {
        let dto = {
          name: tag.name,
          featured: tag.featured,
        };
        const { data } = await TheHubService.createTag(dto);
        if (tag.image) {
          await TheHubService.uploadTagImage(data.id, tag.image);
        }

        this.$toast.success("Tag was successfully created.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText = "Could not create tag, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.closeModals();
        this.loading = false;
        await this.getTags();
      }
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 15,
        search: "",
        parent_id: "0",
      };
      this.getTags();
    },
    showCreateModal() {
      this.$bvModal.show("modal-create-tag");
    },
    closeModals() {
      this.$bvModal.hide("modal-create-tag");
    },
  },
};
</script>

<style lang="scss"></style>
